@import '~antd/dist/antd.css';

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #FFFFFF;
  z-index: 10;
  padding: 16px 4%; 
  box-shadow: 0 2px 8px #f0f1f2;
}

.content-container {
  flex: 1;
  padding-top: 20px; /* Adjust this value for desired spacing */
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  margin: 0 4%; 
}

.footer {
  width: 100%;
  margin-top: 40px;
  background-color: rgba(28, 28, 30, 1);
  padding: 20px 4%;
}

.flex-center {
  display: flex;
  place-content: center;
  align-items: center;
}

.flex-center-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.header-title {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 24px;
}

.header-title:hover {
  color: rgba(10, 132, 255, 1)
}

.header-section {
  margin: auto;
  padding: 2px 0px;
}

.header-input {
  margin-right: 12px;
  width: 200px;
}

a:hover {
  color: rgba(0, 0, 0, 1);
}

.ant-anchor {
  font-size: 15px;
  padding: 20px 20px;
}

.ant-anchor-ink::before {
  position: relative;
  display: block;
  width: 0px;
  height: 100%;
  margin: 0 auto;
  content: '';
}

.ant-anchor-ink-ball {
  width: 1px;
  height: 8px;
  border: 1px solid #222;
  border-radius: 8px;
}

.ant-anchor-link-title.ant-anchor-link-title-active {
  color: rgba(0, 0, 0, 1);
}

.ant-col.header-section.flex-center-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media (max-width: 768px) {
  .header {
    padding: 12px 4%;
  }

  .content-container {
    padding-top: 10px; /* Adjust for mobile */
  }

  .content {
    margin: 0 2%;
  }

  .header-title {
    font-size: 20px;
  }

  .ant-col.header-section.flex-center-left {
    width: 100%;
    margin-top: 8px;
  }

  .header-input {
    margin-right: 0;
    margin-bottom: 8px;
  }
}
