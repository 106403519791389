.section-header {
  margin: 16px 0 0 0;
  padding: 8px;
}

.section-title {
  display: inline;
  margin: 0 16px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 24px;
}

.info-tag {
  display: inline;
  font-size: 18px;
  margin: auto 0;
}

.data-card {
  padding: 12px 12px;
}

.stats-card {
  display: inline-block;
  margin: 0 12px 0 0;
  padding: 6px 12px 12px 12px;
}

.ant-tag.repo-tag {
  border-width: 0;
  background: 0%;
}